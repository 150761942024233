import {
  baseFetch,
  canUseDOM,
  fetchWithAuth,
  fetchUserSettings,
  fetchUserFavorites,
  fetchUserEntitlements,
  fetchUserDetails,
  getWindow,
  type UserLocation,
  type WavetrakOptions,
  findSubdivisionCode,
} from '@surfline/web-common';
import { Cookies } from 'react-cookie';
import { GeoLocationResponse } from 'types/location';
import type { WavetrakContext } from 'types/wavetrak';
import type {
  UserDetails,
  UserEntitlements,
  UserEntitlementsResponse,
  UserLegecyEntitlements,
  UserPermissionsResponse,
  UserSettings,
  UserState,
} from 'types/user';
import config from 'config';
import addCustomUserAgentHeader from 'utils/addCustomUserAgentHeader';
import addGeoHeaders from 'utils/addGeoHeaders';
import type { UserFavorites, UserFavoritesResponse } from 'types/userFavorites';
import getCloudflareGeo from 'utils/getCloudlfareGeo';
import { isNil, omitBy } from 'lodash';
import type { Permission } from 'types/permissions';
import getCookies from 'utils/getCookies';

export const fetchUserFavoritesWrapper = async (
  fetchOptions: RequestInit | undefined,
  wavetrakOptions: WavetrakOptions,
  isServer: boolean,
): Promise<{ favorites?: UserFavorites; error?: string }> => {
  try {
    const response = (await fetchUserFavorites(fetchOptions, {
      ...wavetrakOptions,
      customUrl: isServer ? config.kbygServiceURL : `${config.servicesURL}/kbyg`,
    })) as UserFavoritesResponse;
    return {
      favorites: response?.data?.favorites ?? [],
    };
  } catch (error: any) {
    return {
      error: error?.message || 'Error fetching user favorites',
    };
  }
};

const fetchUserDetailsWrapper = async (
  fetchOptions: RequestInit | undefined,
  wavetrakOptions: WavetrakOptions,
  isAuthenticated: boolean,
  isServer: boolean,
  userServiceURL: string,
): Promise<{ details?: UserDetails; error?: string }> => {
  if (!isAuthenticated) return {};
  try {
    return {
      details: await fetchUserDetails(fetchOptions, {
        ...wavetrakOptions,
        customUrl: userServiceURL,
        useAccessTokenQueryParam: !isServer,
      }),
    };
  } catch (error: any) {
    return {
      error: error?.message || 'Error fetching user details',
    };
  }
};

const fetchUserSettingsWrapper = async (
  fetchOptions: RequestInit | undefined,
  wavetrakOptions: WavetrakOptions,
  isServer: boolean,
  userServiceURL: string,
): Promise<{ settings?: UserSettings; error?: string }> => {
  try {
    const response = (await fetchUserSettings(fetchOptions, {
      ...wavetrakOptions,
      customUrl: userServiceURL,
      useAccessTokenQueryParam: !isServer,
    })) as UserSettings;
    return {
      settings: response,
    };
  } catch (error: any) {
    console.error(`Error fetching user settings: ${error?.message}`, {
      fetchOptions,
      wavetrakOptions,
      isServer,
      userServiceURL,
    });
    return {
      error: error?.message || 'Error fetching user settings',
    };
  }
};

const fetchUserEntitlementsWrapper = async (
  fetchOptions: RequestInit | undefined,
  wavetrakOptions: WavetrakOptions,
  isAuthenticated: boolean,
  isServer: boolean,
): Promise<{
  entitlements: UserEntitlements;
  hasNewAccount: boolean;
  isGuestUser: boolean;
  legacyEntitlements: Array<UserLegecyEntitlements>;
  promotions: UserEntitlements;
  error?: string;
}> => {
  if (!isAuthenticated) {
    return {
      entitlements: [],
      legacyEntitlements: [],
      hasNewAccount: false,
      isGuestUser: false,
      promotions: [],
    };
  }
  try {
    const entitlementsServiceURL = isServer
      ? config.subscriptionServiceURL
      : `${config.servicesURL}`;

    const response = (await fetchUserEntitlements(fetchOptions, {
      ...wavetrakOptions,
      customUrl: entitlementsServiceURL,
      useAccessTokenQueryParam: !isServer,
    })) as UserEntitlementsResponse;
    return {
      entitlements: response.entitlements,
      hasNewAccount: response.hasNewAccount,
      isGuestUser: response.isGuestUser,
      legacyEntitlements: response.legacyEntitlements,
      promotions: response.promotions,
    };
  } catch (error: any) {
    return {
      entitlements: [],
      error: error?.message || 'Error fetching user entitlements',
      hasNewAccount: false,
      isGuestUser: false,
      legacyEntitlements: [],
      promotions: [],
    };
  }
};

const fetchUserPermissionsWrapper = async (
  fetchOptions: RequestInit | undefined,
  wavetrakOptions: WavetrakOptions,
  isAuthenticated: boolean,
  isServer: boolean,
): Promise<{
  error?: string;
  permissions: Array<Permission>;
}> => {
  if (!isAuthenticated) return { permissions: [] };
  try {
    const subscriptionServiceURL = isServer
      ? config.subscriptionServiceURL
      : `${config.servicesURL}`;
    const response = (await fetchWithAuth('/permissions/user', fetchOptions, {
      ...wavetrakOptions,
      customUrl: subscriptionServiceURL,
      useAccessTokenQueryParam: !isServer,
    })) as UserPermissionsResponse;
    return {
      permissions: response.permissions,
    };
  } catch (error: any) {
    return {
      permissions: [],
      error: error?.message || 'Error fetching user permissions',
    };
  }
};
const fetchSubscriptionWarningsWrapper = async (
  fetchOptions: RequestInit | undefined,
  wavetrakOptions: WavetrakOptions,
  isAuthenticated: boolean,
  isServer: boolean,
): Promise<{
  error?: string;
  warnings: UserState['warnings'];
}> => {
  if (!isAuthenticated) return { warnings: [] };
  try {
    const subscriptionServiceURL = isServer
      ? config.subscriptionServiceURL
      : `${config.servicesURL}`;
    return {
      warnings: await fetchWithAuth('/payment/alerts?product=sl', fetchOptions, {
        ...wavetrakOptions,
        customUrl: subscriptionServiceURL,
        useAccessTokenQueryParam: !isServer,
      }),
    };
  } catch (error: any) {
    return {
      warnings: [],
      error: error?.message || 'Error fetching user subscription warnings',
    };
  }
};

const fetchUserLocationWrapper = async (
  isServer: boolean,
  ipAddress: string,
): Promise<{
  location?: UserLocation;
  subdivisions?: GeoLocationResponse['subdivisions'];
  error?: string;
}> => {
  try {
    const baseUrl = isServer ? config.geoTargetServiceUrl : `${config.servicesURL}/geo-target`;
    const response = (await baseFetch(
      `${baseUrl}/region?ipAddress=${ipAddress}`,
    )) as GeoLocationResponse;
    return { location: response?.location, subdivisions: response?.subdivisions };
  } catch (error: any) {
    return {
      error: error?.message || 'Error fetching user favorites',
    };
  }
};

const checkForFatalErrors = (
  fetchErrors: Record<string, string | undefined>,
  fatalError = false,
  isServer = false,
) => {
  const actualFetchErrors = omitBy(fetchErrors, isNil) as Record<string, string>;
  if (actualFetchErrors) {
    if (isServer) {
      const newrelic = require('newrelic');
      newrelic.addCustomAttributes(actualFetchErrors);
    } else {
      const newrelic = getWindow()?.newrelic;
      if (newrelic && typeof newrelic.addCustomAttributes === 'function') {
        newrelic.addCustomAttributes(actualFetchErrors);
      }
    }
  }

  if (fatalError) {
    const error = new Error('Fatal server-side fetchUserData error.');
    if (isServer) {
      const newrelic = require('newrelic');
      newrelic.noticeError(error, {
        ...actualFetchErrors,
      });
    } else {
      const newrelic = getWindow()?.newrelic;
      if (newrelic && typeof newrelic.noticeError === 'function') {
        getWindow()?.newrelic?.noticeError(error, { ...actualFetchErrors });
      }
    }
    throw error;
  }
};

const fetchAllUserData = async ({
  accessToken,
  userId,
  countryCode,
  clientIp,
  location,
  isServer = false,
}: {
  accessToken: string | null;
  userId: string | null | undefined;
  countryCode: string;
  clientIp: string;
  location: { latitude: number; longitude: number };
  isServer: boolean;
}): Promise<UserState | null> => {
  const isAuthenticated = isServer ? !!accessToken && !!userId : !!accessToken;
  const userServiceURL = isServer ? config.userServiceURL : config.servicesURL;
  const fetchOptions = isServer
    ? {
        headers: {
          ...addCustomUserAgentHeader(isServer),
          ...addGeoHeaders(countryCode, location, isServer),
          ...(userId && { 'x-auth-userid': userId }),
        },
      }
    : {};

  const wavetrakOptions =
    (isServer || !canUseDOM) && !!accessToken
      ? {
          serverSideCookies: { access_token: accessToken },
        }
      : {};

  const [
    favorites,
    userDetails,
    userSettings,
    entitlements,
    subscriptionWarnings,
    userLocation,
    permissions,
  ] = await Promise.all([
    fetchUserFavoritesWrapper(fetchOptions, wavetrakOptions, isServer),
    fetchUserDetailsWrapper(
      fetchOptions,
      wavetrakOptions,
      isAuthenticated,
      isServer,
      userServiceURL,
    ),
    fetchUserSettingsWrapper(fetchOptions, wavetrakOptions, isServer, userServiceURL),
    fetchUserEntitlementsWrapper(fetchOptions, wavetrakOptions, isAuthenticated, isServer),
    fetchSubscriptionWarningsWrapper(fetchOptions, wavetrakOptions, isAuthenticated, isServer),
    fetchUserLocationWrapper(isServer, clientIp),
    fetchUserPermissionsWrapper(fetchOptions, wavetrakOptions, isAuthenticated, isServer),
  ]);

  const fatalError = userSettings?.error || permissions?.error;
  checkForFatalErrors(
    {
      detailsError: userDetails?.error,
      entitlementsError: entitlements?.error,
      favoritesError: favorites?.error,
      settingsError: userSettings?.error,
      permissionsError: permissions?.error,
    },
    !!fatalError,
    isServer,
  );

  return {
    countryCode,
    subdivisionCode: userLocation?.subdivisions
      ? findSubdivisionCode(userLocation?.subdivisions)
      : null,
    ip: clientIp,
    // @ts-ignore: user details needs to be null when the user isn't set.
    details: userDetails?.details?._id
      ? {
          ...userDetails?.details,
          firstName: userDetails?.details?.firstName,
          lastName: userDetails?.details?.lastName,
          email: userDetails?.details?.email,
          _id: userDetails?.details?._id,
          isEmailVerified: userDetails?.details?.isEmailVerified,
          // We don't want the hashed/salted password in the store
          password: undefined,
        }
      : null,
    entitlements: entitlements?.entitlements ?? [],
    favorites: favorites?.favorites ?? [],
    hasNewAccount: entitlements?.hasNewAccount ?? false,
    isGuestUser: entitlements?.isGuestUser ?? false,
    legacyEntitlements: entitlements?.legacyEntitlements ?? [],
    promotionEntitlements: entitlements?.promotions ?? [],
    // note: settings will never be undefined because we treat a settings error as a fatal error and throw an exception above
    settings: userSettings.settings as UserSettings,
    warnings: subscriptionWarnings.warnings,
    location: {
      ...location,
      time_zone: userLocation?.location?.time_zone ?? 'America/Los_Angeles',
    },
    permissions: permissions.permissions,
  };
};

export const getAccessToken = (req: any, res: any): string | null => {
  try {
    const win = getWindow();
    return !req && win?.document?.cookie
      ? new Cookies(win?.document?.cookie)?.get('access_token')
      : getCookies(req, res)?.access_token;
  } catch (error) {
    return null;
  }
};

export const getUserRegion = async () => {
  const url = `${config.servicesURL}/geo-target/region?`;
  return baseFetch(url);
};

export const getUser = async (
  context: WavetrakContext,
  isServer = false,
): Promise<UserState | null> => {
  const { req, res } = context;
  if (isServer && !req) {
    return null;
  }
  const accessToken = getAccessToken(req, res);
  const geoData = getCloudflareGeo(req);

  /* The Next.js middleware under /src/middleware.ts sets the `x-auth-userid` header
  in the response, but only if the user passes the authentication checks */
  const userId = res?.getHeader('x-auth-userid')?.toString() || null;
  const wasUserLoggedOut = res?.getHeader('x-auth-user-logged-out') === 'true';
  const userData = await fetchAllUserData({
    accessToken,
    userId,
    countryCode: geoData.countryCode,
    clientIp: geoData.clientIp,
    location: geoData.location,
    isServer,
  });
  if (userData) {
    userData.wasLoggedOut = wasUserLoggedOut;
  }

  return userData;
};

export default getUser;
