import {
  FETCH_SPOT_DETAILS,
  FETCH_SPOT_DETAILS_SUCCESS,
  FETCH_SPOT_DETAILS_FAILURE,
} from 'actions/spot';
import { LEAVE_SPOT_ROUTE } from 'actions/routes';
import createReducer from 'reducers/createReducer';

export const initialState = {};

const handlers = {};

handlers[FETCH_SPOT_DETAILS] = (state, { loading }) => ({
  ...state,
  data: null,
  loading,
});

handlers[FETCH_SPOT_DETAILS_SUCCESS] = (state, { details }) => ({
  ...state,
  data: details.spot,
  loading: false,
});

handlers[FETCH_SPOT_DETAILS_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

handlers[LEAVE_SPOT_ROUTE] = () => initialState;

export default createReducer(handlers, initialState);
