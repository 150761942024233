import { isAfterGivenHour, oneDaySeconds } from 'utils/time';
import createReducer from '../../createReducer';
import {
  FETCH_GRAPH_CONDITION_FORECAST,
  FETCH_GRAPH_CONDITION_FORECAST_SUCCESS,
  FETCH_GRAPH_CONDITION_FORECAST_FAILURE,
} from '../../../actions/graphs';

export const initialState = {
  error: null,
  loading: true,
  units: null,
  utcOffset: null,
  days: null,
};

const handlers = {};

handlers[FETCH_GRAPH_CONDITION_FORECAST] = () => initialState;

handlers[FETCH_GRAPH_CONDITION_FORECAST_SUCCESS] = (state, { response, isPremium = false }) => {
  // ensure we have 16 days of data when not premium
  const returnedDays = response.data.conditions;
  const [lastDayOfData] = returnedDays.slice(-1);
  const missingDays = Array.from({ length: 16 - returnedDays.length }, (_, index) => ({
    am: null,
    forecastDay: null,
    forecaster: null,
    human: false,
    observation: null,
    pm: null,
    timestamp: lastDayOfData.timestamp + oneDaySeconds * (index + 1),
    utcOffset: lastDayOfData.utcOffset,
  }));

  const isAfter2pm = isAfterGivenHour(response.associated.timezone, 14);

  const combinedDays = isPremium
    ? returnedDays
    : [...returnedDays, ...missingDays].map((day, index) => ({
        ...day,
        forecaster: day.forecaster || null,
        human: false,
        headline: isAfter2pm && index === 0 ? day.headline : null,
        observation: isAfter2pm && index === 0 ? day.observation : null,
      }));

  return {
    ...state,
    days: combinedDays,
    lastPublished: response.data.lastPublished,
    loading: false,
    timezone: response.associated.timezone,
    units: response.associated.units,
    utcOffset: response.associated.utcOffset,
  };
};

handlers[FETCH_GRAPH_CONDITION_FORECAST_FAILURE] = (state, { error }) => ({
  ...state,
  error,
  loading: false,
});

export default createReducer(handlers, initialState);
