import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { Typography } from '@mui/material';

import { CamIcon } from 'components/Icons';
import SurfConditions from 'components/SurfConditions';
import WavetrakLink from 'components/WavetrakLink';
import type { TaxonomyNavigatorSettings } from 'types/header';
import type { FavoriteItem } from 'types/favorites';
import conditionIsFlat from 'utils/conditionIsFlat';
import conditionClassModifier from 'utils/conditionClassModifier';
import getUnitType from 'utils/getUnitType';
import { roundSurfHeight } from 'utils/roundUnits';

import spotPagePath from '../Header/components/helpers/spotPagePath';
import generateSlug from '../Header/components/helpers/generateSlug';

import styles from './Favorite.module.scss';

const cx = classNames.bind(styles);

const getConditionsClassName = (className: string, conditions: string) =>
  cx({
    [className]: true,
    [`sl-conditions-color--${conditionClassModifier(conditions)}-background`]: true,
  });

const favoriteClasses = (isLarge: boolean, isMobile: boolean, isDisabled: boolean) =>
  cx({
    favorite: true,
    'favorite--disabled': isDisabled,
    'favorite--large': isLarge,
    'favorite--mobile': isMobile,
    'quiver-favorite': true,
    'quiver-favorite--disabled': isDisabled,
    'quiver-favorite--large': isLarge,
    'quiver-favorite--mobile': isMobile,
  });

interface FavoriteProps {
  disableLink?: boolean;
  favorite: FavoriteItem;
  large?: boolean;
  mobile?: boolean;
  onClickLink?: ({ name, properties }: { name: string; properties: any }) => void;
  settings?: TaxonomyNavigatorSettings;
  withCamIcon?: boolean;
}

export const Favorite = ({
  disableLink = false,
  favorite,
  large = false,
  mobile = false,
  onClickLink,
  settings,
  withCamIcon = false,
}: FavoriteProps) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { _id, name, conditions, waveHeight, cameras } = favorite;
  const isFlat = conditionIsFlat(waveHeight?.max ?? 0, settings?.units?.surfHeight ?? '');
  const surfHeights =
    isFlat === false
      ? `${roundSurfHeight(
          waveHeight?.min || 0,
          settings?.units?.surfHeight ?? 'ft',
        )}-${roundSurfHeight(waveHeight?.max || 1, settings?.units?.surfHeight ?? 'ft')}`
      : 'FLAT';
  const slug = generateSlug(name || '');
  const trackingProps = {
    favoriteName: name,
  };
  const hasCam = cameras && cameras.length;
  const isMultiCam = cameras && cameras.length > 1;
  const handleOnClick = () => {
    if (onClickLink) {
      onClickLink({ name: 'Clicked Favorite', properties: trackingProps });
    }
  };
  const router = useRouter();
  const href = useMemo(() => spotPagePath(_id || '', slug), [_id, slug]);
  const isExternal = useMemo(() => router.asPath.includes?.(href), [href, router.asPath]);
  const content = useMemo(
    () => (
      <>
        {!large ? (
          <div className={getConditionsClassName('condition', conditions?.value ?? '')} />
        ) : null}
        <div className={cx('wrapper', 'quiver-favorite__item__wrapper')}>
          <div className={cx('wrapperTop', 'quiver-favorite__item__wrapper__top')}>
            <Typography
              className={cx('name', 'quiver-favorite__item__wrapper__top-name')}
              data-testid="favorite-name"
              component="span"
              variant="footnote"
            >
              {name}
            </Typography>
            {(large || withCamIcon) && hasCam ? <CamIcon isMultiCam={isMultiCam ?? false} /> : null}
          </div>
          <div className={cx('wrapperBottom', 'quiver-favorite__item__wrapper__bottom')}>
            {large ? <SurfConditions conditions={conditions?.value ?? 'LOTUS'} /> : null}
            <Typography
              className={cx('height', 'quiver-favorite__item__wrapper__bottom-height')}
              data-testid="favorite-height"
              component="span"
              variant="callout1"
            >
              {surfHeights}
            </Typography>
            <Typography
              className={cx('units', 'quiver-favorite__item__wrapper__bottom-units')}
              data-testid="favorite-units"
              component="span"
              variant="callout1"
            >
              {!isFlat ? getUnitType(settings?.units?.surfHeight) : null}
            </Typography>
          </div>
        </div>
      </>
    ),
    [
      conditions?.value,
      hasCam,
      isFlat,
      isMultiCam,
      large,
      name,
      settings?.units?.surfHeight,
      surfHeights,
      withCamIcon,
    ],
  );

  return disableLink === true ? (
    <div className={favoriteClasses(large, mobile, true)} data-testid="favorite" key={name}>
      {content}
    </div>
  ) : (
    <WavetrakLink
      className={favoriteClasses(large, mobile, false)}
      data-testid="favorite"
      href={href}
      isExternal={isExternal}
      key={name}
      onClick={handleOnClick}
    >
      {content}
    </WavetrakLink>
  );
};

export default Favorite;
