import productCDN from '@surfline/quiver-assets';
import type { EnvConfig } from 'config';

const mapTilerKey = 'q4MNPLHAJ58Ggb35ZrXf';

const production: EnvConfig = {
  appHost: 'https://www.surfline.com',
  appKeys: {
    appsFlyer: '6079b7b5-3365-4204-a7ba-e94a1b940e38',
    fbAppId: process.env.FB_APP_ID || '218714858155230',
    logrocket: 'h57nhx/sl_prod',
    segment: 'Se6IGuzcvUhLx55hLHL0RiXkS6oUTz8L',
  },
  camerasServiceURL: 'http://cameras-service.prod.surfline.com',
  cdnUrl: 'https://wa.cdn-surfline.com',
  clientId: process.env.WEB_CLIENT_ID || '5c59e7c3f0b6cb1ad02baf66',
  clientSecret: process.env.WEB_CLIENT_SECRET || '',
  defaultCountryCode: 'US',
  defaultImageUrl: 'https://spot-thumbnails.cdn-surfline.com/spots/default/default_1500.jpg',
  ga4MeasurementId: 'G-P2906QJGH2',
  geoTargetServiceUrl: 'http://geo-target-service.prod.surfline.com',
  kbygServiceURL: 'http://kbyg-api.prod.surfline.com',
  legacyApi: 'https://api.surfline.com/v1',
  mapTiler: {
    key: mapTilerKey,
    style: `https://api.maptiler.com/maps/5c2beb01-3d14-40b5-8bb4-463b7948c4a6/style.json?key=${mapTilerKey}`,
    publicKey: 'igHgJOU9X0VrMtGuzlM6',
  },
  oneTrustDomainScript: '89f3dcce-1ffc-42b2-9d52-91fd3af3d675',
  react: {
    integrity:
      'sha512-qlzIeUtTg7eBpmEaS12NZgxz52YYZVF5myj89mjJEesBd/oE9UPsYOX2QAXzvOAZYEvQohKdcY8zKE02ifXDmA==',
    src: `${productCDN}/scripts/react.production.min.js`,
  },
  reactDOM: {
    integrity:
      'sha512-9jGNr5Piwe8nzLLYTk8QrEMPfjGU0px80GYzKZUxi7lmCfrBjtyCc1V5kkS5vxVwwIB7Qpzc7UxLiQxfAN30dw==',
    src: `${productCDN}/scripts/react-dom.production.min.js`,
  },
  servicesURL: 'https://services.surfline.com',
  she: {
    headerScriptUrl: 'https://ads.blogherads.com/sk/12/123/1236760/28994/header.js',
    staticScriptUrl: 'https://ads.blogherads.com/static/blogherads.js',
  },
  signInUrl: 'https://www.surfline.com/sign-in',
  splitio: process.env.SPLITIO_AUTHORIZATION_KEY || 'in20v2ebf6q1gh97ldcn1bp8ufdbr404e514',
  subscriptionServiceURL: 'http://subscription-service.prod.surfline.com',
  surflineEmbedURL: 'https://embed.cdn-surfline.com',
  surflineHost: 'https://www.surfline.com',
  userServiceURL: 'http://user-service.prod.surfline.com',
  wpAPI: 'https://www.surfline.com',
};

export default production;
