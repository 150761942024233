/* eslint-disable @typescript-eslint/naming-convention */
import React, { MouseEvent } from 'react';
import classnames from 'classnames';
import { Typography } from '@mui/material';

import WavetrakLink from 'components/WavetrakLink';
import type { SearchResult as SearchResultInterface } from 'types/header';

import { buildBreadCrumb, buildNameContent, buildIconContent, getResultHref } from '../../helpers';

import styles from './SearchResult.module.scss';

const getSearchResultTitleClasses = (index: string) =>
  classnames({
    [styles.title]: true,
    [styles['title--news']]: index === 'feed',
  });

interface SearchResultProps {
  handleClick: (result: SearchResultInterface) => void;
  result: SearchResultInterface;
  searchTerm: string;
}

const SearchResult = ({ result, searchTerm, handleClick }: SearchResultProps) => {
  const { _index, isSuggestion, _source, _type } = result;

  const newsSuggestion = _index === 'feed';

  const nameContent = buildNameContent(
    _source?.content,
    _source?.name,
    isSuggestion,
    newsSuggestion,
    searchTerm,
  );
  const iconContent = buildIconContent(
    _source?.cams,
    _type || '',
    newsSuggestion,
    _source?.insightsCameraId || null,
  );
  const breadCrumb = buildBreadCrumb(_source?.breadCrumbs, newsSuggestion, _source?.tags);
  const handleOnClick = (event: MouseEvent<HTMLElement>) => {
    if (handleClick) {
      handleClick(result);
    }
    event.preventDefault();
  };

  return (
    <li className={styles.result}>
      <Typography
        component={WavetrakLink}
        href={getResultHref(result) || ''}
        className={styles.link}
        variant="body1"
        onClick={handleOnClick}
      >
        {iconContent}
        {/* eslint-disable no-underscore-dangle */}
        <div className={getSearchResultTitleClasses(result?._index ?? '')}>
          {nameContent}
          <span className={styles.subtitle}>{breadCrumb}</span>
        </div>
      </Typography>
    </li>
  );
};

export default SearchResult;
