const getUserType = (entitlements: string[], isUser: boolean) => {
  if (entitlements.includes('sl_vip_advertiser')) return 'VIP_ADVERTISER';
  if (entitlements.includes('sl_vip')) return 'VIP';
  if (entitlements.includes('sl_premium_ads')) return 'PREMIUM_WITH_ADS';
  if (entitlements.includes('sl_premium')) return 'PREMIUM';
  if (entitlements.includes('sl_youth')) return 'PREMIUM';
  if (isUser) return 'REGISTERED';
  return 'ANONYMOUS';
};

export default getUserType;
