import 'isomorphic-fetch';
import { nrNoticeError } from '@surfline/web-common';

import baseFetch from 'common/baseFetch';
import config from 'config';

export const getSpotMapData = async ({
  north,
  south,
  east,
  west,
  observationClarity,
}: {
  north: number;
  south: number;
  east: number;
  west: number;
  observationClarity?: boolean;
}) => {
  let url = `/kbyg/mapview?south=${south}&west=${west}&north=${north}&east=${east}`;
  if (observationClarity === true) url = `${url}&observationClarity=true`;
  return baseFetch(url);
};

export const getNearestSpot = async ({
  lat,
  lon,
  observationClarity,
}: {
  lat: number;
  lon: number;
  observationClarity?: boolean;
}) => {
  let url = `/kbyg/mapview/spot?lat=${lat}&lon=${lon}`;
  if (observationClarity === true) url = `${url}&observationClarity=true`;
  return baseFetch(url);
};

export const fetchLocationMapView = async (geonameId: string) => {
  const url = `${config.servicesURL}/location/view?type=mapView&id=${geonameId}`;

  const response = await fetch(url);

  const body = await response.json();

  if (response.status === 400) {
    throw new Error(`Bad request: ${response.statusText}`);
  }

  if (response.status > 200) {
    nrNoticeError(new Error(response.statusText), {
      url,
      geonameId,
      responseStatus: response.status,
    });
    throw new Error(`Failed to fetch location map view: ${response.statusText}`);
  }
  return body;
};
