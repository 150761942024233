import createReducer from '../createReducer';
import { SET_MAP_LOCATION } from '../../actions/map';
import { LEAVE_GEONAME_MAP_ROUTE } from '../../actions/routes';

export const initialState = {
  center: {
    lat: null,
    lon: null,
  },
  zoom: 12,
};

const handlers = {};

handlers[SET_MAP_LOCATION] = (state, { center, zoom }) => ({
  ...state,
  center,
  zoom,
});

handlers[LEAVE_GEONAME_MAP_ROUTE] = () => initialState;
export default createReducer(handlers, initialState);
