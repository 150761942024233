import React from 'react';

import {
  CamIcon,
  CameraInsightsIcon,
  ForecastIcon,
  LocationIcon,
  NewsIcon,
  TravelIcon,
} from 'components/Icons';

const buildIconContent = (
  cams: any,
  type?: string,
  newsSuggestion?: boolean,
  insightsCameraId?: null | string,
) => {
  let iconContent;
  if (type === 'spot' && insightsCameraId) {
    iconContent = <CameraInsightsIcon />;
  } else if (cams && cams.length && cams[0] !== null) {
    iconContent = <CamIcon isMultiCam={cams && cams.length > 1} />;
  } else if (type === 'spot' || type === 'subregion') {
    iconContent = <ForecastIcon />;
  } else if (type === 'travel') {
    iconContent = <TravelIcon />;
  } else if (newsSuggestion) {
    iconContent = <NewsIcon />;
  } else {
    iconContent = <LocationIcon />;
  }
  return iconContent;
};

export default buildIconContent;
