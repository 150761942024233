/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import getConfig from 'next/config';
import { AppEnv } from '../types';
import baseConfig from './base';

export const env: AppEnv =
  process.env.APP_ENV || getConfig()?.publicRuntimeConfig?.APP_ENV || 'development';

export interface EnvConfig {
  appHost: string;
  appKeys: {
    appsFlyer?: string;
    fbAppId: string;
    logrocket: string;
    segment: string;
  };
  camerasServiceURL: string;
  cdnUrl: string;
  clientId: string;
  clientSecret: string;
  defaultCountryCode: string;
  defaultImageUrl: string;
  ga4MeasurementId?: string;
  geoTargetServiceUrl: string;
  kbygServiceURL: string;
  legacyApi: string;
  mapTiler: {
    key: string;
    style: string;
    publicKey: string;
  };
  oneTrustDomainScript: string;
  react: {
    integrity: string;
    src: string;
  };
  reactDOM: {
    integrity: string;
    src: string;
  };
  robots?: string;
  servicesURL: string;
  she: {
    headerScriptUrl: string;
    staticScriptUrl: string;
  };
  signInUrl: string;
  skipTrackingScripts?: boolean;
  splitio: string;
  subscriptionServiceURL: string;
  surflineEmbedURL: string;
  surflineHost: string;
  userServiceURL: string;
  wpAPI: string;
}

export interface EnvConfigBase {
  accountSettingsUrl: string;
  appStoreUrl: {
    iOS: string;
  };
  buoyPlotApi: string;
  camStillURL: string;
  chartEmbedClientIds: string[];
  childGridThreeCol: number;
  childGridTwoCol: number;
  cloudflareImageResizingUrl: (src: string, query?: string) => string;
  createAccountUrl: string;
  desktopWidth: number;
  desktopXLWidth: number;
  desktopXXLWidth: number;
  facebookOGImage: string;
  funnelUrl: string;
  hostForSEO: string;
  mapTileKey: string;
  mapTileUrl: string;
  meta: any;
  mobileWidth: number;
  multiCamConditionsPollingInterval: number;
  rangeSelectorWidths: {
    oneDay: number;
    twoDay: number;
    threeDay: number;
    fourDay: number;
    fiveDay: number;
  };
  smallMobileWidth: number;
  surfNetworkBaseUrl: string;
  tabletLargeWidth: number;
  twitterOGImage: string;
  numberOfRewindDays: number;
}

type EnvConfigCombined = EnvConfig & EnvConfigBase;

const envConfig = require(`./envs/${env}`) as { default: EnvConfig };

const config: EnvConfigCombined = {
  ...baseConfig,
  ...(envConfig.default || {}),
};

export default config;
