import { getSpotMapData } from 'common/api/maps';
import type { AppThunk } from 'stores';
import type { BoundingBox } from 'types/map';
import type { SpotReportData } from 'types/spot';
import type { Units } from 'types/units';

export const FETCH_SPOT_MAP_DATA = 'FETCH_SPOT_MAP_DATA';
export const FETCH_SPOT_MAP_DATA_SUCCESS = 'FETCH_SPOT_MAP_DATA_SUCCESS';
export const FETCH_SPOT_MAP_DATA_FAILURE = 'FETCH_SPOT_MAP_DATA_FAILURE';

interface SpotMapDataSuccessAction {
  type: typeof FETCH_SPOT_MAP_DATA_SUCCESS;
  spots: SpotReportData[];
  regionalForecast: any;
  subregions: any[];
  units: Units;
  zoom: number;
  doCluster: boolean;
}

interface SpotMapDataFailureAction {
  type: typeof FETCH_SPOT_MAP_DATA_FAILURE;
  error: string;
}

interface SpotMapDataRequestAction {
  type: typeof FETCH_SPOT_MAP_DATA;
}

export type SpotMapDataActionTypes =
  | SpotMapDataRequestAction
  | SpotMapDataSuccessAction
  | SpotMapDataFailureAction;

interface GetSpotMapDataResponse {
  data: {
    spots: SpotReportData[];
    regionalForecast: any;
    subregions: any[];
  };
  units: Units;
}

export const fetchSpotMapData =
  (
    { north, south, east, west }: BoundingBox,
    doCluster = false,
    observationClarity = false,
  ): AppThunk =>
  async (dispatch, getState) => {
    dispatch({
      type: FETCH_SPOT_MAP_DATA,
    });

    try {
      const spotMapData = (await getSpotMapData({
        north,
        south,
        east,
        west,
        observationClarity,
      })) as GetSpotMapDataResponse;
      dispatch({
        type: FETCH_SPOT_MAP_DATA_SUCCESS,
        spots: spotMapData.data.spots,
        regionalForecast: spotMapData.data.regionalForecast,
        subregions: spotMapData.data.subregions,
        units: spotMapData.units,
        zoom: getState().map.location.zoom,
        doCluster,
      });
    } catch (error) {
      dispatch({
        type: FETCH_SPOT_MAP_DATA_FAILURE,
        error: error instanceof Error ? error.message : 'An unknown error occurred',
      });
    }
  };
