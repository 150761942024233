import React from 'react';

export const ChevronRight = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle height="44" width="44" cx="22" cy="22" r="21.5" stroke="#424242" />
      <path
        height="44"
        width="44"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0152 28.6783C18.6406 28.2725 18.6659 27.6398 19.0717 27.2652L24.7757 22L19.0717 16.7348C18.6659 16.3602 18.6406 15.7275 19.0152 15.3217C19.3898 14.9159 20.0225 14.8906 20.4283 15.2652L26.9283 21.2652C27.1334 21.4545 27.25 21.7209 27.25 22C27.25 22.2791 27.1334 22.5455 26.9283 22.7348L20.4283 28.7348C20.0225 29.1094 19.3898 29.0841 19.0152 28.6783Z"
        fill="#E4E7ED"
      />
    </g>
  </svg>
);

export default ChevronRight;
