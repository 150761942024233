import { useEffect, useMemo, useState, FC, SyntheticEvent, useCallback } from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { Stack, Box, Tab, Tabs, Skeleton } from '@mui/material';
import { conditionClassModifier, getUserSettings, getUserDetails } from '@surfline/web-common';

import { BRAZE_CONTENT_CARDS } from 'common/constants';
import { SL_WEB_REWIND_RECAPS } from 'common/treatments';
import ContentCard from 'components/ContentCard';
import ForecastHeaderDetails from 'components/ForecastHeaderDetails';
import FavoriteButton from 'components/FavoriteButton';
import NearbySpotsMenu from 'components/NearbySpotsMenu';
import WavetrakLink from 'components/WavetrakLink';
import { useMinWidthTablet } from 'hooks/useMediaQueries';
import usePreferredForecastView from 'hooks/usePreferredForecastView';
import { getNearbySpots } from 'selectors/spot';
import { useUserPreferredForecastView } from 'selectors/user';
import { useAppSelector } from 'stores/hooks';
import type { SubregionOverviewData } from 'types/subregion';
import type { SpotDetails, SpotReportData } from 'types/spot';
import type { SurfHeight } from 'types/units';
import { useTreatments } from 'utils/treatments';

import { type PageType, PageTypes, usePageDetails, usePageLinks } from './hooks/usePageDetails';

import styles from './ForecastHeader.module.scss';

const cx = classNames.bind(styles);

const getConditionsClassName = (className: string, conditions: string) =>
  cx({
    [className]: true,
    [`sl-conditions-color--${conditionClassModifier(conditions)}-background`]: true,
  });

interface Props {
  conditions: string;
  hasLiveCam: boolean;
  pageTitle: string;
  pageType: PageType;
  spot?: SpotDetails;
  subregionOverview: SubregionOverviewData;
  isRegionalCharts?: boolean;
}

const ForecastHeader: FC<Props> = ({
  conditions,
  hasLiveCam,
  isRegionalCharts = false,
  pageTitle,
  pageType = PageTypes.SPOT as PageType,
  spot,
  subregionOverview,
}) => {
  const router = useRouter();
  const treatments = useTreatments();
  const isDesktop = useMinWidthTablet();
  const preferredForecastView = useUserPreferredForecastView();
  const nearbySpots: Array<SpotReportData> | undefined = useAppSelector(getNearbySpots);
  const userSettings = useAppSelector(getUserSettings);
  const userDetails = useAppSelector(getUserDetails);
  const isAnonymous = !userDetails;
  const { preferredForecastViewLocalStorage } = usePreferredForecastView(isAnonymous);

  const isLoading = !spot?._id || !spot?.name;
  const isSpotPage = pageType === PageTypes.SPOT;
  const isSpotGuidePage = pageType === PageTypes.SPOT_GUIDE;
  const isSpotChartPage = pageType === PageTypes.SPOT_CHARTS;
  const isSubregionForecastPage = pageType === PageTypes.REGIONAL_ANALYSIS;
  const isTableQuery = router?.query?.view === 'table';

  const subregionForecastStatus = useMemo(() => {
    if (isSubregionForecastPage) return 'active';
    return subregionOverview.forecastSummary.forecastStatus.status;
  }, [isSubregionForecastPage, subregionOverview]);

  const showNearbySpots =
    nearbySpots && isDesktop && (isSpotPage || isSpotGuidePage || isSpotChartPage);

  const pageDetails = usePageDetails({
    hasLiveCam,
    pageType: pageType as PageType,
    subregionForecastStatus,
  });

  const pageLinks = usePageLinks({
    isRegionalCharts,
    spotId: spot?._id,
    spotLat: spot?.lat,
    spotLon: spot?.lon,
    spotName: spot?.name,
    subregionForecastStatus,
    subregionOverview,
    tableView:
      isTableQuery ||
      (isAnonymous
        ? preferredForecastViewLocalStorage === 'TABLE'
        : preferredForecastView === 'TABLE'),
  });

  const [tabIndex, setTabIndex] = useState(pageDetails.tab);

  const memoizedNearbySpots = useMemo(
    () => (nearbySpots?.length ? [...(nearbySpots as Array<SpotReportData>)] : []),
    [nearbySpots],
  );

  const handleTabChange = useCallback((_: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  }, []);

  const pageTabs = useMemo(
    () =>
      pageLinks.map((link: { text: string; title: string; path: string; newWindow: boolean }) => (
        <Tab
          className={styles.tab}
          data-testid="forecast-header-tab"
          key={`${link.text}-tab`}
          LinkComponent={WavetrakLink}
          disableFocusRipple
          disableRipple
          label={link.text}
          title={link.title}
          href={link.path}
          target={link.newWindow ? '_blank' : ''}
        />
      )),
    [pageLinks],
  );

  useEffect(() => {
    if (tabIndex !== pageDetails.tab) setTabIndex(pageDetails.tab);
  }, [pageDetails.tab, tabIndex]);

  return (
    <Box className={styles.forecastHeaderWrapper} data-testid="forecast-header">
      {isLoading ? (
        <Skeleton
          data-testid="forecast-header-loading-skeleton"
          variant="rectangular"
          width="100%"
          height={50}
          sx={{ marginBottom: '5px', background: 'transparent' }}
        />
      ) : (
        <>
          {isSpotPage && (
            <ContentCard
              card={{ name: BRAZE_CONTENT_CARDS.SPOT_HEADER, extras: null }}
              testId="banner-braze-card"
              className={styles.contentCard}
            />
          )}
          <Stack direction="row" justifyContent="space-between" className={styles.headerTitle}>
            <Stack className={styles.headerDetails} direction="row">
              <Box className={getConditionsClassName('conditionsBlock', conditions)} />
              <ForecastHeaderDetails
                title={pageTitle}
                subtitle={pageDetails.subtitle}
                isDesktop={isDesktop}
              />
            </Stack>
            {showNearbySpots ? (
              <NearbySpotsMenu
                nearbySpots={memoizedNearbySpots}
                rawUnits={userSettings?.units?.surfHeight as SurfHeight}
              />
            ) : null}
            {(isSpotPage || isSpotGuidePage || isSpotChartPage) && (
              <FavoriteButton spotId={spot?._id} />
            )}
          </Stack>
        </>
      )}
      {!spot?._id ? (
        <Skeleton
          data-testid="forecast-header-loading-skeleton"
          variant="rectangular"
          width="100%"
          height={40}
          sx={{ marginBottom: '8px', background: 'transparent' }}
        />
      ) : (
        <Tabs
          aria-label="sub navigation"
          data-testid="forecast-header-tabs"
          indicatorColor="secondary"
          onChange={handleTabChange}
          scrollButtons={false}
          textColor="secondary"
          value={tabIndex}
          variant="scrollable"
        >
          {pageTabs}
          {spot.insightsCameraId && treatments[SL_WEB_REWIND_RECAPS] === 'on' && (
            <Tab
              className={styles.tab}
              data-testid="forecast-header-tab"
              key="forecast-header-tab"
              label="Rewind Recaps"
              LinkComponent={WavetrakLink}
              disableFocusRipple
              disableRipple
              href={`/rewind-recaps?spotId=${spot._id}`}
              target="_blank"
            />
          )}
        </Tabs>
      )}
    </Box>
  );
};

export default ForecastHeader;
