import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import {
  Backdrop,
  Button,
  ClickAwayListener,
  Grow,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { conditionClassModifier, slugify, trackEvent } from '@surfline/web-common';

import { CamIcon, CameraInsightsIcon } from 'components/Icons';
import type { SpotReportData } from 'types/spot';
import type { SurfHeight as SurfHeightType } from 'types/units';
import { NearbySubregion } from 'types/subregion';
import formatWaveHeight from 'utils/formatWaveHeight';
import conditionIsFlat from 'utils/conditionIsFlat';
import { spotReportPath } from 'utils/urls/spotReportPath';

import styles from './NearbySpotsMenu.module.scss';

const cx = classNames.bind(styles);
const getConditionsClassName = (className: string, conditions: string) =>
  cx({
    [className]: true,
    [`sl-conditions-color--${conditionClassModifier(conditions)}-background`]: true,
  });

interface Props {
  nearbySpots?: Array<SpotReportData>;
  rawUnits: SurfHeightType | undefined;
}

const EndIcon = ({ open }: { open: boolean }) => (
  <span className={styles.endIcon}>{open ? <ExpandLess /> : <ExpandMore />}</span>
);

const NearbySpotsMenu = ({ nearbySpots, rawUnits }: Props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const units = formatWaveHeight(rawUnits?.toUpperCase() || '') as SurfHeightType;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const dropdownItemLink = (data: SpotReportData | NearbySubregion) =>
    spotReportPath({}, { _id: data._id, name: slugify(data.name) });

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const nearbySpotsButtonClass = cx(styles.nearbySpotsButton, { [styles.isOpen]: !!open });

  return (
    <Stack>
      <div>
        <Backdrop
          data-testid="nearby-spots-backdrop"
          className={styles.nearbySpotsBackdrop}
          open={open}
        />
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'nearby-spots-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className={nearbySpotsButtonClass}
          data-testid="nearby-spots-button"
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          endIcon={<EndIcon open={open} />}
          onClick={handleToggle}
          size="medium"
          variant="secondary"
        >
          <span className={styles.popoverTriangle} style={{ opacity: open ? 1 : 0 }} />
          Nearby spots
        </Button>
        <Popper
          className={styles.popperWrapper}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left top',
              }}
            >
              <Paper className={styles.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    className={styles.menuWrap}
                    data-testid="nearby-spots-menu"
                  >
                    {nearbySpots?.map((data: SpotReportData) => (
                      <MenuItem
                        key={data?.name}
                        className={styles.nearbySpotLink}
                        data-testid="nearby-spots-link"
                        href={dropdownItemLink(data)}
                        onClick={() => {
                          trackEvent('Clicked Nearby Spot', {
                            spotId: data._id,
                            spotName: data.name,
                          });
                        }}
                        title={`${data.name} Surf Report and Forecast`}
                        disableRipple
                        component={Link}
                      >
                        <div
                          data-testid="nearby-spots-menu-item-condition-bar"
                          className={getConditionsClassName(
                            'conditionsBlock',
                            data?.conditions?.value || '',
                          )}
                        />
                        <Typography
                          variant="body"
                          className={styles.nearbySpotsLinkName}
                          data-testid="nearby-spots-menu-item-name"
                          noWrap
                        >
                          {data?.name}
                        </Typography>
                        {conditionIsFlat(data?.waveHeight?.max, units) ? (
                          <Typography
                            variant="callout1"
                            className={styles.surfHeightOverrides}
                            data-testid="nearby-spots-menu-item-flat"
                          >
                            Flat
                          </Typography>
                        ) : (
                          <Typography variant="callout1" data-testid="nearby-spots-menu-item-size">
                            {Math.round(data?.waveHeight?.min)}-{Math.round(data?.waveHeight?.max)}
                            {units?.toLowerCase()}
                            {data?.waveHeight?.plus ? '+' : ''}
                          </Typography>
                        )}
                        {data.cameras.length > 0 && !data.insightsCameraId && (
                          <CamIcon
                            className={styles.iconWrapper}
                            isMultiCam={data.cameras.length > 1}
                            withText={data.cameras.length > 1}
                          />
                        )}
                        {data.insightsCameraId && (
                          <span className={styles.insightsIconWrapper}>
                            <CameraInsightsIcon />
                          </span>
                        )}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
};

export default NearbySpotsMenu;
