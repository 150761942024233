/* istanbul ignore file */

import { useEffect, useRef, useState, useCallback } from 'react';
import { canUseDOM, nrNoticeError } from '@surfline/web-common';

/**
 * @param {string} componentName
 * @param {import('react').ReactNode} loader
 * @returns
 */
export const useLeafletComponent = (componentName, loader = null) => {
  const componentRef = useRef(loader);
  const isUnmounting = useRef(false);

  // We need a state update to properly trigger a re-render
  // since mutating a ref instance does not trigger a re-render
  // eslint-disable-next-line no-unused-vars
  const [_, setLoaded] = useState(false);

  // Callback function which executes the import, and then grabs the
  // desired component from `react-leafet`
  const importLeafletComponent = useCallback(async () => {
    try {
      // Webpack Module Comments which allow us to tweak the bundle behavior more directly
      // https://webpack.js.org/api/module-methods/#magic-comments
      const leaflet = await import(
        /* webpackChunkName: "react-leaflet" */
        /* webpackMode: "lazy" */
        /* webpackPreload: true */
        '@monsonjeremy/react-leaflet'
      );
      const component = leaflet[componentName];

      if (!component) {
        throw new Error(`react-leaflet component ${componentName} does not exist.`);
      }

      componentRef.current = component;

      if (!isUnmounting.current) {
        setLoaded(true);
      }
    } catch (error) {
      nrNoticeError(error, { componentName });
      if (!isUnmounting.current) {
        console.error(`Error loading leaflet component ${componentName}:`, error);
      }
    }
  }, [componentName]);

  useEffect(() => {
    if (canUseDOM) {
      importLeafletComponent();
    }
    return () => {
      isUnmounting.current = true;
    };
  }, [importLeafletComponent]);

  return componentRef.current;
};

/**
 * @param {string} importName
 */
export const useLeafletCoreImport = (importName) => {
  const importRef = useRef();
  const isUnmounting = useRef(false);

  // We need a state update to properly trigger a re-render
  // since mutating a ref instance does not trigger a re-render
  // eslint-disable-next-line no-unused-vars
  const [_, setLoaded] = useState(false);

  // Callback function which executes the import, and then grabs the
  // desired component from `react-leafet`
  const importLeafletComponent = useCallback(async () => {
    try {
      // Webpack Module Comments which allow us to tweak the bundle behavior more directly
      // https://webpack.js.org/api/module-methods/#magic-comments
      const leafletCore = await import(
        /* webpackChunkName: "react-leaflet-core" */
        /* webpackMode: "lazy" */
        /* webpackPreload: true */
        '@monsonjeremy/react-leaflet-core'
      );
      const component = leafletCore[importName];

      if (!component) {
        throw new Error(`react-leaflet-core ${importName} does not exist.`);
      }

      importRef.current = component;

      if (!isUnmounting.current) {
        setLoaded(true);
      }
    } catch (error) {
      nrNoticeError(error, { importName });
      if (!isUnmounting.current) {
        console.error(`Error loading leaflet core import ${importName}:`, error);
      }
    }
  }, [importName]);

  useEffect(() => {
    if (canUseDOM) {
      importLeafletComponent();
    }
    return () => {
      isUnmounting.current = true;
    };
  }, [importLeafletComponent]);

  return importRef.current;
};
