import { slugify } from '@surfline/web-common';

export const subregionForecastPaths = {
  base: '/surf-forecasts',
};

const subregionForecastPath = ({
  isNative,
  spotId,
  subregionId,
  subregionName,
  subregions,
  showShareIcons,
}: {
  isNative?: boolean;
  spotId?: string;
  subregionId: string;
  subregionName: string;
  subregions?: boolean;
  showShareIcons?: boolean;
}) => {
  const params = new URLSearchParams();
  if (spotId) params.append('spotId', spotId);
  if (subregions) params.append('subregions', String(subregions));
  if (isNative) {
    params.append('native', String(isNative));
  }
  if (showShareIcons) {
    params.append('showShareIcons', String(showShareIcons));
  }
  const queryString = params.toString();
  return `${subregionForecastPaths.base}/${slugify(subregionName)}/${subregionId}${
    queryString ? `?${queryString}` : ''
  }`;
};

export default subregionForecastPath;
