import { toNumber } from 'lodash';
import { getClientIp } from '@surfline/web-common';

import { DEFAULT_SURFLINE_LOCATION } from 'common/constants';
import config from 'config';
import type { WavetrakRequest } from 'types/wavetrak';

const getCloudflareGeo = (req: WavetrakRequest | undefined) => ({
  clientIp: getClientIp(req) || '',
  countryCode:
    req?.headers?.['cf-ipcountry']?.toString() ||
    req?.headers?.['cloudfront-viewer-country']?.toString() ||
    config.defaultCountryCode,
  location: {
    latitude: req?.headers?.['cf-iplatitude']
      ? toNumber(req?.headers?.['cf-iplatitude'])
      : DEFAULT_SURFLINE_LOCATION.center.lat,
    longitude: req?.headers?.['cf-iplongitude']
      ? toNumber(req?.headers?.['cf-iplongitude'])
      : DEFAULT_SURFLINE_LOCATION.center.lon,
  },
});

export default getCloudflareGeo;
