import 'isomorphic-fetch';

import baseFetch, { createParamString } from 'common/baseFetch';
import config from 'config';
import type { BatchSpotReportViews, PopularSpotReportViews } from 'types/spot';
import type { Units } from 'types/units';
import addClientIpHeader from 'utils/addClientIpHeader';
import addGeoCountryCodeHeader from 'utils/addGeoCountryCodeHeader';

export const fetchReport = ({
  clientIp = null,
  cookies,
  countryCode = null,
  observationClarity,
  spotId,
}: {
  clientIp?: string | null;
  cookies?: Record<string, string>;
  countryCode?: string | null;
  observationClarity?: boolean;
  spotId: string;
}) => {
  const clientIpHeader = addClientIpHeader({ clientIp });
  const countryCodeHeader = addGeoCountryCodeHeader({ countryCode });

  const queryParams: Record<string, any> = { spotId };

  // Only include observationClarity when it's true
  if (observationClarity === true) {
    queryParams.observationClarity = true;
  }

  return baseFetch(`/kbyg/spots/reports?${createParamString(queryParams)}`, {
    cookies,
    headers: {
      ...clientIpHeader,
      ...countryCodeHeader,
    },
  });
};

export const fetchNearbySpots = (
  spotId: string,
  cookies: Record<string, string>,
  clientIp = null,
  countryCode = null,
  observationClarity?: boolean,
) => {
  const clientIpHeader = addClientIpHeader({ clientIp });
  const countryCodeHeader = addGeoCountryCodeHeader({ countryCode });

  const queryParams: Record<string, any> = { spotId };

  // Only include observationClarity when it's true
  if (observationClarity === true) {
    queryParams.observationClarity = true;
  }

  return baseFetch(`/kbyg/spots/nearby?${createParamString(queryParams)}`, {
    cookies,
    headers: {
      ...clientIpHeader,
      ...countryCodeHeader,
    },
  });
};

export const fetchSpotDetails = (spotId: string, cookies: Record<string, string>) =>
  baseFetch(`/kbyg/spots/details?spotId=${spotId}`, { cookies });

export const fetchBuoys = async (legacySpotId: string, units: Units) => {
  const url = `${config.legacyApi}/mobile/nearby/${legacySpotId}?resources=buoy&unit=${units}&buoys=6`;
  const response = await fetch(url);
  const body = await response.json();
  if (response.status > 200) throw body;
  return body;
};

export const fetchBatchSubregions = (subregionIds: Array<string>) =>
  baseFetch(`/subregions/batch?${createParamString({ subregionIds })}`);

export const fetchNearestSpot = (lat: number, lon: number, observationClarity?: boolean) => {
  const queryParams: Record<string, any> = { lat, lon };

  // Only include observationClarity when it's true
  if (observationClarity === true) {
    queryParams.observationClarity = true;
  }

  return baseFetch(`/kbyg/mapview/spot?${createParamString(queryParams)}`);
};

interface FetchSpotReportViewsData {
  units:
    | {
        swellHeight?: string;
        temperature?: string;
        tideHeight?: string;
        waveHeight?: string;
        windSpeed?: string;
        surfHeight?: string;
      }
    | undefined;
  noCache?: string;
  observationClarity?: boolean;
}

interface FetchSpotReportViewsOptions {
  method: string;
  headers: {
    Accept: string;
    'Content-Type': string;
    'Cache-Control'?: string;
  };
}

export const fetchSpotReportViews = async (
  spotIds: Array<string>,
  data: FetchSpotReportViewsData = {
    units: undefined,
    noCache: 'false',
  },
): Promise<BatchSpotReportViews> => {
  let cacheEnabled = !!(
    data.units?.surfHeight &&
    data.units?.swellHeight &&
    data.units?.temperature &&
    data.units?.tideHeight &&
    data.units?.windSpeed
  );

  const options: FetchSpotReportViewsOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (data?.noCache?.toLowerCase() === 'true') {
    options.headers['Cache-Control'] = 'no-cache';
    cacheEnabled = false;
  }

  let url = `/kbyg/spots/batch?${createParamString({
    cacheEnabled,
    'units[swellHeight]': data.units?.swellHeight ?? null,
    'units[temperature]': data.units?.temperature ?? null,
    'units[tideHeight]': data.units?.tideHeight ?? null,
    'units[waveHeight]': data.units?.surfHeight ?? null,
    'units[windSpeed]': data.units?.windSpeed ?? null,
  })}`;

  // Only add observationClarity when it's true
  if (data.observationClarity === true) {
    url = `${url}&${createParamString({ observationClarity: true })}`;
  }

  // Only add spotIds query param if there are any passed in.
  if (spotIds.length > 0) {
    // Sort the spot ids alphanumerically so they match the server response.
    // This ensures caching.
    const sortedSpotIds = spotIds.sort((a, b) =>
      a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: 'base',
      }),
    );
    url = `${url}&${createParamString({ spotIds: sortedSpotIds })}`;
  }

  return baseFetch(url, options);
};

export const fetchPopularSpots = async (
  geoCountryIso?: string,
  observationClarity?: boolean,
): Promise<PopularSpotReportViews> => {
  let url = `/kbyg/spots/popular?geoCountryIso=${geoCountryIso}`;

  // Only include observationClarity when it's true
  if (observationClarity === true) {
    url = `${url}&observationClarity=true`;
  }

  return baseFetch(url);
};
