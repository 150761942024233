import createReducer from '../createReducer';
import { FETCH_SPOT_MAP_DATA_SUCCESS } from '../../actions/spotMapData';

export const initialState = { subregionId: null };

const handlers = {};

handlers[FETCH_SPOT_MAP_DATA_SUCCESS] = (_, { regionalForecast, doCluster }) =>
  doCluster || !regionalForecast ? initialState : regionalForecast;

export default createReducer(handlers, initialState);
