import React from 'react';

export const ChevronLeft = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <circle width="44" height="44" cx="22" cy="22" r="21.5" stroke="#424242" />
      <path
        height="44"
        width="44"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9848 15.3217C25.3594 15.7275 25.3341 16.3602 24.9283 16.7348L19.2243 22L24.9283 27.2652C25.3341 27.6398 25.3594 28.2725 24.9848 28.6783C24.6102 29.0841 23.9775 29.1094 23.5717 28.7348L17.0717 22.7348C16.8666 22.5455 16.75 22.2791 16.75 22C16.75 21.7209 16.8666 21.4545 17.0717 21.2652L23.5717 15.2652C23.9775 14.8906 24.6102 14.9159 24.9848 15.3217Z"
        fill="#E4E7ED"
      />
    </g>
  </svg>
);

export default ChevronLeft;
