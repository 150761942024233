export interface StaticLink {
  display: string;
  href: string;
  isExternal?: boolean;
  newWindow?: boolean;
  splitTreatment?: string;
  showNewNotification?: boolean;
  eventTracking?: Record<string, string | number | boolean>;
}

export const homeLinks: Array<StaticLink> = [
  {
    display: 'Home',
    href: '/',
    isExternal: false,
    newWindow: false,
  },
];

export const contestLinks: Array<StaticLink> = [
  {
    display: 'Surf Etiquette',
    href: 'https://www.surfline.com/lp/surf-etiquette',
    isExternal: true,
    newWindow: false,
  },
  {
    display: 'Maps to Nowhere',
    href: 'https://go.surfline.com/mapstonowhere',
    isExternal: true,
    newWindow: false,
  },
];

export const mapLinks: Array<StaticLink> = [
  {
    display: 'Surf spots',
    href: '/surf-reports-forecasts-cams-map',
    isExternal: false,
    newWindow: false,
  },
  {
    display: 'Wave buoys',
    href: '/surf-reports-forecasts-cams-map/buoys',
    isExternal: false,
    newWindow: false,
  },
  {
    display: 'Wind stations',
    href: '/surf-reports-forecasts-cams-map/wind-stations',
    isExternal: false,
    newWindow: false,
  },
  { display: 'Charts', href: '/surf-charts', isExternal: false, newWindow: false },
];

export const newsLinks: Array<StaticLink> = [
  { display: 'Video', href: '/category/video', isExternal: false, newWindow: false },
  { display: 'Swells', href: '/category/swells', isExternal: false, newWindow: false },
  { display: 'Features', href: '/category/features', isExternal: false, newWindow: false },
  { display: 'Travel', href: '/category/travel', isExternal: false, newWindow: false },
  { display: 'Gear', href: '/category/gear', isExternal: false, newWindow: false },
  { display: 'Training', href: '/category/training', isExternal: false, newWindow: false },
  { display: 'Forecast', href: '/category/forecast', isExternal: false, newWindow: false },
  { display: 'All', href: '/surf-news', isExternal: false, newWindow: false },
];

export const getAccountLinks = (showManageMembersLink: boolean = false): Array<StaticLink> => [
  { display: 'Account', href: '/account', isExternal: true, newWindow: false },
  ...(showManageMembersLink
    ? [
        {
          display: 'Add people to your membership',
          href: '/subscriptions/manage-members',
          isExternal: true,
          newWindow: false,
          showNewNotification: true,
          eventTracking: {
            buttonText: 'Add people to your membership',
          },
        },
      ]
    : []),
  { display: 'Settings', href: '/settings', isExternal: false, newWindow: false },
];

export const promotionalLinks: Array<StaticLink> = [
  { display: 'Gift Cards', href: '/gift-cards', isExternal: true, newWindow: false },
];

export const productLinks: Array<StaticLink> = [
  {
    display: 'Multi-Cam',
    href: `/surf-cams`,
    newWindow: false,
  },
  { display: 'Favorites', href: '/favorites?type=live', isExternal: true, newWindow: false },
];

export const defaultAccountLinks: Array<StaticLink> = [
  ...getAccountLinks(),
  ...promotionalLinks,
  ...productLinks,
];

export const camsReportsLinks: Array<StaticLink> = [
  {
    display: 'All Surf Spots',
    href: '/surf-reports-forecasts-cams',
    isExternal: false,
    newWindow: false,
  },
  {
    display: 'Multi-Cam',
    href: `/surf-cams`,
    isExternal: false,
    newWindow: false,
  },
  {
    display: 'Meet the Forecasters',
    href: '/lp/forecasters',
    isExternal: true,
    newWindow: true,
  },
  { display: 'Hurricanes', href: '/series/hurricane', isExternal: false, newWindow: false },
];
