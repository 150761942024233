import { getTime } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { isNil, sortedIndex } from 'lodash';

export const convertToSeconds = (timestamp: number) => Math.round(timestamp / 1000);

// to convert seconds to minutes AND minutes to hours
export const divideTimeBySixty = (t: number) => Math.round(t / 60);

export const oneSecond = 1000;
export const oneMinute = oneSecond * 60;
export const oneHour = oneMinute * 60;
export const oneDay = oneHour * 24;

export const oneMinuteSeconds = convertToSeconds(oneMinute);
export const oneHourSeconds = convertToSeconds(oneHour);
export const oneDaySeconds = convertToSeconds(oneDay);

export const convertToMilliseconds = (t: number) => t * 1000;
export const convertUtcOffsetToMilliseconds = (offset: number) => offset * 60 * 60 * 1000;
export const convertMillisecondsToHours = (t: number) =>
  divideTimeBySixty(divideTimeBySixty(convertToSeconds(t)));

export const millisecondsToHoursAndMinutesAsDecimal = (t: number): number =>
  Math.round(t / oneHour / 0.25) * 0.25;

export const oneHourMilliseconds = convertToMilliseconds(oneHourSeconds);

export const floorToMidnightForTZ = (timestamp: number, timezone: string = 'Etc/GMT') => {
  const formattedTimestampForCurrentDay = formatInTimeZone(timestamp, timezone, 'yyyy-MM-dd');
  const zonedTime = getTime(
    zonedTimeToUtc(`${formattedTimestampForCurrentDay} 00:00:00`, timezone),
  );
  return zonedTime;
};

export const ceilToMidnightForTZ = (timestamp: number, timezone: string = 'Etc/GMT') => {
  const timestampPlusADay = timestamp + oneDay;
  return floorToMidnightForTZ(timestampPlusADay, timezone);
};

export const midnightTodayInTZ = (timezone: string = 'Etc/GMT') =>
  floorToMidnightForTZ(Date.now(), timezone);

export const midnightTomorrowInTZ = (timezone: string) => midnightTodayInTZ(timezone) + oneDay;

export const midnightTomorrow = (timezone: string = 'Etc/GMT') =>
  midnightTodayInTZ(timezone) + oneDay;

export const formatTimestampInTimezone = (
  timestamp: number,
  utcOffset: number,
  formatString: string,
) => {
  const hours = Math.floor(Math.abs(utcOffset));
  const minutes = Math.floor(60 * (Math.abs(utcOffset) - hours));
  const utcHourString = hours.toString().padStart(2, '0');
  const utcMinutesString = minutes.toString().padEnd(2, '0');
  const prefix = utcOffset < 0 ? '-' : '+';
  const utcOffsetString = `${prefix}${utcHourString}:${utcMinutesString}`;

  return formatInTimeZone(convertToMilliseconds(timestamp), utcOffsetString, formatString);
};

export const utcOffsetToHoursAndMinutes = (utcOffset: number) => {
  const hours = Math.floor(Math.abs(utcOffset));
  const minutes = Math.floor(60 * (Math.abs(utcOffset) - hours));
  const prefix = utcOffset < 0 ? '-' : '+';
  return `${prefix + hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export const hoursThreePerDay = ['6', '12', '18'];
export const hoursThreeHourly = ['0', '3', '6', '9', '12', '15', '18', '21'];

export const isTimestampThreeHourly = (timestamp: number | null, utcOffset: number | null) =>
  timestamp && utcOffset !== null
    ? hoursThreeHourly.indexOf(formatTimestampInTimezone(timestamp, utcOffset, 'H')) >= 0
    : false;

export const isTimestampSixHourly = (timestamp: number | null, utcOffset: number | null) =>
  timestamp && utcOffset !== null
    ? hoursThreePerDay.indexOf(formatTimestampInTimezone(timestamp, utcOffset, 'H')) >= 0
    : false;

/**
 * Finds the closest timestamp to a given reference timestamp
 * @param timestamps - Array of timestamps to search through
 * @param timestamp - Reference timestamp to find closest to
 * @param pastOnly - If true, only considers timestamps before or equal to the reference timestamp
 * @returns The closest timestamp, or null if no valid timestamps found
 */
export const findClosestTimestamp = (timestamps: number[], timestamp: number, pastOnly = false) => {
  if (pastOnly) {
    const pastTimestamps = timestamps.filter((t) => t <= timestamp);
    return pastTimestamps.length ? Math.max(...pastTimestamps) : null;
  }

  const closestIndex = sortedIndex(timestamps, timestamp);
  const closestTimestamp = timestamps[closestIndex];
  const previousTimestamp = timestamps[closestIndex - 1];

  if (isNil(closestTimestamp)) {
    return previousTimestamp;
  }

  if (isNil(previousTimestamp)) {
    return closestTimestamp;
  }

  const closestDifference = Math.abs(closestTimestamp - timestamp);
  const previousDifference = Math.abs(previousTimestamp - timestamp);

  return closestDifference < previousDifference ? closestTimestamp : previousTimestamp;
};

export const isAfterGivenHour = (timezone: string, hour: number) => {
  const nowInForecastTimezone = utcToZonedTime(new Date(), timezone);
  const afterHour = nowInForecastTimezone.getHours() >= hour;
  return afterHour;
};

/**
 * Checks if the current time falls within the hour range of a given timestamp
 * @param timestamp - Unix timestamp in seconds
 * @returns boolean indicating if current time is within the hour of the timestamp
 */
export const isCurrentHour = (timestamp: number): boolean => {
  // Convert timestamp to milliseconds if it's in seconds
  const timestampMs = timestamp * 1000;
  const now = Date.now();

  // Get the hour start and end times for the observation point
  const hourStart = new Date(timestampMs);
  hourStart.setMinutes(0, 0, 0);

  const hourEnd = new Date(hourStart);
  hourEnd.setHours(hourEnd.getHours() + 1);

  // Check if current time falls within this hour range
  return now >= hourStart.getTime() && now < hourEnd.getTime();
};

/**
 * Finds the nearest past timestamp from a list of timestamps
 * @param timestamps - Array of Unix timestamps in seconds
 * @returns The timestamp that is closest to but not after the current time
 */
export const findNearestPastTimestamp = (timestamps: number[]): number | null => {
  if (!timestamps.length) return null;

  const now = Date.now() / 1000; // Convert to seconds to match timestamps

  // Filter timestamps that are in the past and find the most recent one
  const pastTimestamps = timestamps.filter((t) => t <= now);
  if (!pastTimestamps.length) return null;

  return Math.max(...pastTimestamps);
};

/**
 * Checks if the timestamp is the nearest past timestamp from a list
 * @param timestamp - Unix timestamp in seconds
 * @param allTimestamps - Array of all timestamps to compare against
 * @returns boolean indicating if this timestamp is the nearest past one
 */
export const isNearestPastTimestamp = (timestamp: number, allTimestamps: number[]): boolean => {
  const nearestPast = findNearestPastTimestamp(allTimestamps);
  return nearestPast === timestamp;
};
