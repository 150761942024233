import React from 'react';
import PropTypes from 'prop-types';

import { useLeafletComponent } from './useLeaflet';
import MapContext from './MapContext';

/**
 * @typedef {object} Props
 * @property {string} [className]
 * @property {string} [attribution]
 * @property {() => void} [onClose]
 * @property {() => void} [onOpen]
 * @property {import('leaflet').LatLngExpression} [position]
 * @property {React.Node} [children]
 * @property {boolean} [usingPortal]
 * @property {import('leaflet').Map} [map]
 */

const Popup = React.forwardRef(
  /**
   * @param {Props} props
   * @param {React.ForwardedRef<import('leaflet').Popup>} ref
   */
  ({ className, attribution, onClose, onOpen, position, children, usingPortal, map }, ref) => {
    const LeafletPopup = useLeafletComponent('Popup');

    if (!LeafletPopup) {
      return null;
    }

    if (usingPortal) {
      return (
        <MapContext map={map}>
          <LeafletPopup
            attribution={attribution}
            onClose={onClose}
            onOpen={onOpen}
            position={position}
            ref={ref}
            className={className}
          >
            {children}
          </LeafletPopup>
        </MapContext>
      );
    }

    return (
      <LeafletPopup
        attribution={attribution}
        onClose={onClose}
        onOpen={onOpen}
        position={position}
        ref={ref}
        className={className}
      >
        {children}
      </LeafletPopup>
    );
  },
);

Popup.displayName = 'Popup';

Popup.propTypes = {
  attribution: PropTypes.string,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  position: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  children: PropTypes.node,
  usingPortal: PropTypes.bool,
  map: PropTypes.shape(),
  className: PropTypes.string,
};

Popup.defaultProps = {
  className: null,
  attribution: null,
  position: null,
  onOpen: null,
  onClose: null,
  children: null,
  usingPortal: false,
  map: null,
};

export default Popup;
