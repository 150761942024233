/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import analytics from './analytics';
import animations from './animations';
import backplane from './backplane';
import buoys from './buoys';
import components from './components';
import contests from './contests';
import cta from './cta';
import deviceData from './deviceData';
import editorial from './editorial';
import editorialTaxonomyDetails from './editorialTaxonomyDetails';
import editorialTaxonomyPosts from './editorialTaxonomyPosts';
import forecast from './forecast';
import map from './map';
import mapV2 from './mapV2';
import multiCam from './multiCam';
import search from './search';
import spot from './spot';
import status from './status';
import subregion from './subregion';
import swellEvents from './swellEvents';
import topSpots from './topSpots';
import travelLocationView from './travelLocationView';
import units from './units';
import windStations from './windStations';
import worldTaxonomy from './worldTaxonomy';

const rootReducer = () =>
  combineReducers({
    analytics,
    animations,
    backplane,
    components,
    contests,
    cta,
    deviceData,
    editorial,
    editorialTaxonomyDetails,
    editorialTaxonomyPosts,
    forecast,
    map,
    mapV2,
    multiCam,
    search,
    spot,
    status,
    subregion,
    swellEvents,
    topSpots,
    travelLocationView,
    units,
    worldTaxonomy,
    [buoys.path]: buoys.reducer,
    [windStations.path]: windStations.reducer,
  });

export default rootReducer;
