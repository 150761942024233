import cookie from 'cookie';
import { Response } from 'express';
import { type WavetrakRequest } from 'types/wavetrak';

/*
This function is used to work around a Next.JS issue where cookies are stale in the
Server Side rendering life cycle methods (getInitialProps, getServerSideProps)
when the cookie is updated mid request like we were doing with the Express.JS
middleware and still doing with Next.JS middleware:
- https://github.com/vercel/next.js/issues/49442
- https://github.com/vercel/next.js/issues/49442
- https://www.reddit.com/r/nextjs/comments/1ad5ci5/cookies_in_middleware_not_being_visible_to/
- https://stackoverflow.com/questions/75750941/nextjs-middleware-can-not-read-the-cookie-after-set-it-needs-to-reload-the-page

It uses the value from the Set-Cookie header in the response to override the values in the request.
It only tries to parse access_token and refresh_token from the Set-Cookie header string to handle
the case where they were updated from the middleware.
*/
const getCookies = (req: WavetrakRequest | undefined, res: Response<any, Record<string, any>>) => {
  const cookies = req?.cookies || {};
  const newAuthTokenCookies: { access_token?: string; refresh_token?: string } = {};

  try {
    const setCookieHeader = res?.getHeader('Set-Cookie')?.toString() || '';
    const cookiesArray = req && setCookieHeader ? setCookieHeader.split(/,(?=\S)/) : [];
    cookiesArray.forEach((cookieStr) => {
      // Parse each cookie string to see if we have updated auth tokens from the next.js middlewarew
      const parsedCookie = cookie.parse(cookieStr);
      if ('access_token' in parsedCookie) {
        newAuthTokenCookies.access_token = parsedCookie.access_token;
      }
      if ('refresh_token' in parsedCookie) {
        newAuthTokenCookies.refresh_token = parsedCookie.refresh_token;
      }
    });
    return {
      ...cookies,
      ...newAuthTokenCookies,
    };
  } catch (e) {
    // silently ignore error parsing cookie string and just return the original cookies
  }

  return cookies;
};

export default getCookies;
