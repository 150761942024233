import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { canUseDOM } from '@surfline/web-common';
import ReactDOMServer from 'react-dom/server';

import { useLeafletComponent } from './useLeaflet';
import MapContext from './MapContext';

/**
 * @typedef {object} Props
 * @property {React.ReactNode} icon
 * @property {string} [className]
 * @property {import('leaflet').PointExpression} [iconSize]
 * @property {import('leaflet').PointExpression} [iconAnchor]
 * @property {import('leaflet').LatLngLiteral} [position]
 * @property {import('leaflet').Map} [map]
 * @property {boolean} [usingPortal]
 * @property {number} [zIndexOffset]
 * @property {import('leaflet').LeafletEventHandlerFnMap} eventHandlers
 */

const CustomMarker = React.forwardRef(
  /**
   * @param {Props} props
   * @param {React.MutableRefObject<import('leaflet').Marker>} ref
   */
  (
    {
      position: { lat, lon },
      icon,
      className,
      iconSize,
      iconAnchor,
      children,
      usingPortal,
      map,
      zIndexOffset,
      eventHandlers,
    },
    ref,
  ) => {
    const [customIcon, setCustomIcon] = useState(null);

    /** @type {React.FunctionComponent<import('react-leaflet').MarkerProps>} */
    const LeafletMarker = useLeafletComponent('Marker');

    useEffect(() => {
      const getLeaflet = async () => {
        // Webpack Module Comments which allow us to tweak the bundle behavior more directly
        // https://webpack.js.org/api/module-methods/#magic-comments
        const Leaflet = await import(
          /* webpackExclude: /\.css/ */
          /* webpackMode: "lazy" */
          /* webpackPreload: true */
          'leaflet'
        );

        setCustomIcon(
          Leaflet.divIcon({
            html: ReactDOMServer.renderToString(icon),
            className,
            iconSize,
            iconAnchor: iconAnchor || [iconSize / 2, iconSize / 2],
          }),
        );
      };
      if (canUseDOM) {
        getLeaflet();
      }
    }, [icon, iconAnchor, iconSize, className]);

    const isReady = LeafletMarker && customIcon;

    if (!isReady) {
      return null;
    }

    if (usingPortal) {
      return (
        <MapContext map={map}>
          <LeafletMarker
            ref={ref}
            position={{ lat, lon }}
            icon={customIcon}
            zIndexOffset={zIndexOffset}
            eventHandlers={eventHandlers}
          >
            {children}
          </LeafletMarker>
        </MapContext>
      );
    }

    return (
      <LeafletMarker
        ref={ref}
        position={{ lat, lon }}
        icon={customIcon}
        zIndexOffset={zIndexOffset}
        eventHandlers={eventHandlers}
      >
        {children}
      </LeafletMarker>
    );
  },
);

CustomMarker.propTypes = {
  children: PropTypes.node,
  position: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }).isRequired,
  icon: PropTypes.node.isRequired,
  className: PropTypes.string,
  iconSize: PropTypes.arrayOf(PropTypes.number).isRequired,
  iconAnchor: PropTypes.arrayOf(PropTypes.number),
  map: PropTypes.shape(),
  usingPortal: PropTypes.bool,
  zIndexOffset: PropTypes.number,
  eventHandlers: PropTypes.objectOf(PropTypes.func),
};

CustomMarker.defaultProps = {
  children: null,
  className: null,
  iconAnchor: null,
  map: null,
  usingPortal: false,
  zIndexOffset: 0,
  eventHandlers: null,
};

CustomMarker.displayName = 'CustomMarker';

export default CustomMarker;
