/* istanbul ignore file */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState, useMemo } from 'react';
import classNames from 'classnames';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Provider, shallowEqual } from 'react-redux';
import { useRafLoop } from 'react-use';
import { CacheProvider, EmotionCache } from '@emotion/react';

import { getEntitlements, getWindow } from '@surfline/web-common';
import { WavetrakThemeProvider } from '@wavetrak/theme';

import { analyticsReady } from 'actions/analytics';
import { JW_PLAYER_URL, LEAFLET_URL, MAP_TILER_URLS } from 'common/constants';
import AdBlock from 'components/AdBlock';
import AppBanner from 'components/AppBanner';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Scripts from 'components/Scripts';
import AppHeaderAd from 'containers/AppHeaderAd';
import KBYGPageContainer from 'containers/KBYGPageContainer';
import MapV2 from 'containers/MapV2';
import SpotChartsPageContainer from 'containers/SpotChartsPageContainer';
import SpotGuidePageContainer from 'containers/SpotGuidePageContainer';
import SpotPageContainer from 'containers/SpotPageContainer';
import SubregionForecastContainer from 'containers/SubregionForecastContainer';
import useConditions from 'hooks/useConditions';
import { useUserEntitlementStatus } from 'selectors/user';
import { type ReduxInitialProps, useStore, type AppState } from 'stores';
import type { Device } from 'types/device';
import type { MapMode } from 'types/map';
import type { UserState } from 'types/user';
import { instantiateClientSideAnalytics } from 'utils/setupAnalytics';
import setNewRelicErrorHandler from 'utils/setNewRelicErrorHandler';
import createEmotionCache from 'utils/createEmotionCache';
import { Treatments, TreatmentsProvider } from 'utils/treatments';
import { useAppSelector } from 'stores/hooks';
import { getIsPremiumWithAdsUser } from 'utils/user';

import 'react-loading-skeleton/dist/skeleton.css';
import '../styles/base.scss';

const getPageContainerClass = (isNative: boolean) =>
  classNames({
    'quiver-page-container__content': true,
    'quiver-page-container__content--native': isNative,
  });

export interface AppPageProps extends ReduxInitialProps {
  enableCssBaseline?: boolean;
  enableTheme?: boolean;
  isAppBannerEnabled?: boolean;
  isChartsPage?: boolean;
  isFavoritesBarVisible?: boolean;
  isFooterSmall?: boolean;
  isFooterVisible?: boolean;
  // allows page to overwrite route checks deciding when to show header ad
  isHeaderAdEnabled?: boolean;
  isHeaderCTAVisible?: boolean;
  isHeaderVisible?: boolean;
  isMapV2Page?: boolean;
  isPremiumAnalysisPage?: boolean;
  isSpotChartsPage?: boolean;
  isSpotGuidePage?: boolean;
  isSpotPage?: boolean;
  isSubregionForecastPage?: boolean;
  librariesToLoad?: {
    jwplayer?: boolean;
    leaflet?: boolean;
    mapTiler?: boolean;
    videojs?: boolean;
  };
  mapMode?: MapMode;
  noIndex?: boolean;
}

export interface WavetrakAppProps extends AppProps<AppPageProps> {
  device: Device;
  emotionCache?: EmotionCache;
  isNative: boolean;
  pageProps: AppPageProps;
  serverTreatments: Treatments;
  userData: UserState;
}
interface AppContentProps
  extends Pick<WavetrakAppProps, 'Component' | 'pageProps' | 'isNative' | 'device'> {}

setNewRelicErrorHandler();

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const AppContent = ({ Component, pageProps, isNative, device }: AppContentProps) => {
  const {
    isAppBannerEnabled = false,
    isChartsPage,
    isFavoritesBarVisible = true,
    isFooterSmall = false,
    isFooterVisible = true,
    isHeaderAdEnabled = true,
    isHeaderCTAVisible = true,
    isHeaderVisible = true,
    isMapV2Page,
    isPremiumAnalysisPage,
    isSpotChartsPage,
    isSpotGuidePage,
    isSpotPage,
    isSubregionForecastPage,
    mapMode,
  } = pageProps;
  const { themeClassName } = useConditions();

  const isEntitled = useUserEntitlementStatus();
  const entitlements = useAppSelector(getEntitlements, shallowEqual);
  const isPremiumWithAdsUser = getIsPremiumWithAdsUser(entitlements);
  const showAds = !isEntitled || isPremiumWithAdsUser;

  const showHeader = useMemo(() => !(isNative || !isHeaderVisible), [isHeaderVisible, isNative]);

  const showFooter = useMemo(() => !(isNative || !isFooterVisible), [isFooterVisible, isNative]);

  return (
    <div className={themeClassName}>
      {isHeaderAdEnabled && <AppHeaderAd />}
      <Header
        isCTAVisible={isHeaderCTAVisible}
        isFavoritesBarVisible={isFavoritesBarVisible}
        isVisible={showHeader}
      />
      <Scripts />
      {showAds && <AdBlock />}
      <main className={getPageContainerClass(isNative)}>
        <KBYGPageContainer>
          {(() => {
            if (isMapV2Page) {
              return (
                <MapV2 mapMode={mapMode}>
                  <Component {...pageProps} />
                </MapV2>
              );
            }
            if (isSubregionForecastPage || isPremiumAnalysisPage || isChartsPage) {
              return (
                <SubregionForecastContainer isChartsPage={isChartsPage}>
                  <Component {...pageProps} />
                </SubregionForecastContainer>
              );
            }
            if (isSpotGuidePage) {
              return (
                <SpotGuidePageContainer>
                  <Component {...pageProps} />
                </SpotGuidePageContainer>
              );
            }
            if (isSpotChartsPage) {
              return (
                <SpotChartsPageContainer>
                  <Component {...pageProps} />
                </SpotChartsPageContainer>
              );
            }
            if (isSpotPage) {
              return (
                <SpotPageContainer>
                  <Component {...pageProps} />
                </SpotPageContainer>
              );
            }
            return <Component {...pageProps} />;
          })()}
        </KBYGPageContainer>
        {isAppBannerEnabled && !isNative && showAds && <AppBanner device={device} />}
      </main>
      <Footer isVisible={showFooter} isSmall={isFooterSmall} />
    </div>
  );
};

const SurflineApp = ({
  Component,
  device,
  emotionCache = clientSideEmotionCache,
  isNative,
  pageProps,
  serverTreatments,
  userData,
}: WavetrakAppProps) => {
  const [userDataReady, setUserDataReady] = useState(false);
  const {
    enableCssBaseline,
    enableTheme = true,
    librariesToLoad,
    noIndex = false,
    ssrReduxState,
  } = pageProps;
  const win = getWindow();

  // eslint-disable-next-line no-underscore-dangle
  const deviceData = win?.__WAVETRAK_DEVICE__ || device;
  // eslint-disable-next-line no-underscore-dangle
  const treatments = win?.__WAVETRAK_TREATMENTS__ || serverTreatments;
  const router = useRouter();
  const state = useRef<AppState>({
    ...ssrReduxState!,
    // Note: after migrating backplane into kbyg-web we continue to store the user data in redux under backplane
    // as this is referenced in many areas, from many selectors. We can remove this in the future as a separate unit of work.
    backplane: {
      ...ssrReduxState?.backplane,
      user: {
        // eslint-disable-next-line no-underscore-dangle
        ...(win?.__USER_REDUX__ || userData),
      },
    },
  });
  const store = useStore(state.current);

  const [loopStop] = useRafLoop(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (win?.__USER_REDUX__) {
      setUserDataReady(true);
    }
  }, !!win);

  useEffect(
    () => {
      if (userDataReady) {
        loopStop();
        instantiateClientSideAnalytics(store, router, () => store.dispatch(analyticsReady()));
      }
    },
    // We only care about when the user data is ready here and this only needs to be called once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userDataReady],
  );

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
        />
        <meta charSet="utf-8" />
        <meta httpEquiv="Content-Language" content="en" />
        {noIndex && <meta name="robots" content="noindex,nofollow" />}
        {librariesToLoad?.mapTiler && (
          <>
            <link
              rel="preload"
              as="script"
              href={MAP_TILER_URLS.js.src}
              integrity={MAP_TILER_URLS.js.integrity}
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              as="style"
              href={MAP_TILER_URLS.css.src}
              integrity={MAP_TILER_URLS.css.integrity}
              crossOrigin="anonymous"
            />
            <link
              rel="stylesheet"
              href={MAP_TILER_URLS.css.src}
              integrity={MAP_TILER_URLS.css.integrity}
              crossOrigin="anonymous"
            />
          </>
        )}
        {librariesToLoad?.leaflet && (
          <>
            <link
              rel="preload"
              as="style"
              type="text/css"
              href={LEAFLET_URL}
              integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
              crossOrigin="anonymous"
            />
            <link
              rel="stylesheet"
              href={LEAFLET_URL}
              integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
              crossOrigin="anonymous"
            />
          </>
        )}
        {librariesToLoad?.jwplayer && <link rel="preload" as="script" href={JW_PLAYER_URL} />}
      </Head>
      <CacheProvider value={emotionCache}>
        <WavetrakThemeProvider disabled={!enableTheme} disableCssBaseline={!enableCssBaseline} ssr>
          <TreatmentsProvider value={treatments}>
            <Provider store={store}>
              <AppContent
                Component={Component}
                device={deviceData}
                isNative={isNative}
                pageProps={pageProps}
              />
            </Provider>
          </TreatmentsProvider>
        </WavetrakThemeProvider>
      </CacheProvider>
    </>
  );
};

export default SurflineApp;
