import { getWindow } from '@surfline/web-common';
import { getCanUseLocalStorage } from 'utils/canUseLocalStorage';

const win = getWindow();

export const loadSavedNavigation = (savedNavigationType: string) =>
  getCanUseLocalStorage() ? win?.localStorage.getItem(savedNavigationType) || '' : '';

export const saveNavigation = async (taxonomyId: string, savedNavigationType: string) => {
  if (getCanUseLocalStorage()) {
    win?.localStorage.setItem(savedNavigationType, taxonomyId);
  }
};
