import { FEET, HAWAIIAN, METERS } from '@surfline/web-common';
import round from 'lodash/round';
import type { SurfHeight } from 'types/units';

export const roundSurfHeight = (height: number, units: SurfHeight | string) => {
  switch (units.toUpperCase()) {
    case FEET:
      return round(height, 0);
    case HAWAIIAN:
      return round(height, 0);
    case METERS:
      return round(height, 1);
    default:
      return height;
  }
};

const defaultExport = { roundSurfHeight };

export default defaultExport;
