export const getSpotDetails = (state) => state.spot?.report?.data?.spot ?? null;

export const getSpotCameras = (state) => state.spot?.report?.data?.spot?.cameras ?? [];

export const getSpotLotusStatus = (state) => state.spot?.details?.data?.sds ?? null;

export const getSpotReport = (state) => state.spot?.report?.data?.report ?? null;

export const getTravelDetails = (state) => state.spot?.report?.data?.spot?.travelDetails ?? null;

export const getSpotIdealConditions = (state) =>
  state.spot?.report?.data?.spot?.travelDetails?.best ?? null;

export const getSpotReportForecast = (state) => state.spot?.report?.data?.forecast ?? null;

export const getSpotReportLiveData = (state) => state.spot?.report?.data?.live ?? null;

export const getSpotReportNotes = (state) => state.spot?.report?.data?.notes ?? null;

export const getSpotReportAssociated = (state) => state.spot?.report?.associated ?? null;

export const getAdvertisingIds = (state) => state.spot?.report?.associated?.advertising ?? null;

export const getReverseSortBeachView = (state) => state.spot?.nearby?.reverseSortBeachView;

export const getNearbySpots = (state) => state.spot?.nearby?.data?.spots ?? null;

export const getSpotBuoys = (state) => state.spot?.buoys ?? null;

export const getSpotLoading = (state) =>
  state.spot?.loading || state.spot?.report?.loading || state.spot?.details?.loading;
