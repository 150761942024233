const addGeoHeaders = (
  countryCode: string,
  location: { latitude: number; longitude: number },
  isServer = false,
) =>
  isServer
    ? {
        'cf-ipcountry': countryCode,
        'cf-iplatitude': location?.latitude?.toString(),
        'cf-iplongitude': location?.longitude?.toString(),
        'x-geo-country-iso': countryCode,
        'x-geo-latitude': location?.latitude?.toString(),
        'x-geo-longitude': location?.longitude?.toString(),
      }
    : null;

export default addGeoHeaders;
