import { useMediaQuery } from '@mui/material';
import theme from '@wavetrak/theme';

const mediumMobileWidth = '375px';
export const largeMobileWidth = '512px';

export const useMaxWidthMobileMedium = () =>
  useMediaQuery(`(max-width: ${parseInt(mediumMobileWidth, 10) - 1}px)`);
export const useMaxWidthMobileLarge = () =>
  useMediaQuery(`(max-width: ${parseInt(largeMobileWidth, 10) - 1}px)`);

export const useMaxWidthTablet = () =>
  useMediaQuery(theme.breakpoints.down('tablet'), { defaultMatches: true });
export const useMaxWidthTabletLarge = () => useMediaQuery(theme.breakpoints.down('tabletLg'));
export const useMaxWidthDesktopSmall = () => useMediaQuery(theme.breakpoints.down('desktopSm'));
export const useMaxWidthDesktop = () => useMediaQuery(theme.breakpoints.down('desktop'));
export const useMaxWidthDesktopMedium = () => useMediaQuery(theme.breakpoints.down('desktopMd'));
export const useMaxWidthDesktopLarge = () => useMediaQuery(theme.breakpoints.down('desktopLg'));
export const useMaxWidthDesktopExtraLarge = () =>
  useMediaQuery(theme.breakpoints.down('desktopXl'));

export const useMinWidthMobileMedium = () =>
  useMediaQuery(`(min-width: ${mediumMobileWidth})`, { defaultMatches: true });
export const useMinWidthMobileLarge = () =>
  useMediaQuery(`(min-width: ${largeMobileWidth})`, { defaultMatches: true });

export const useMinWidthTablet = () => useMediaQuery(theme.breakpoints.up('tablet'));
export const useMinWidthTabletLarge = () => useMediaQuery(theme.breakpoints.up('tabletLg'));
export const useMinWidthDesktopSmall = () => useMediaQuery(theme.breakpoints.up('desktopSm'));
export const useMinWidthDesktop = () => useMediaQuery(theme.breakpoints.up('desktop'));
export const useMinWidthDesktopMedium = () => useMediaQuery(theme.breakpoints.up('desktopMd'));
export const useMinWidthDesktopLarge = () => useMediaQuery(theme.breakpoints.up('desktopLg'));
export const useMinWidthDesktopExtraLarge = () => useMediaQuery(theme.breakpoints.up('desktopXl'));
