import {
  FETCH_GRAPH_SURF_FORECAST,
  FETCH_GRAPH_SURF_FORECAST_SUCCESS,
  FETCH_GRAPH_SURF_FORECAST_FAILURE,
  FETCH_GRAPH_LIVE_SURF_FORECAST,
  FETCH_GRAPH_LIVE_SURF_FORECAST_SUCCESS,
  FETCH_GRAPH_LIVE_SURF_FORECAST_FAILURE,
} from 'actions/graphs';
import createReducer from 'reducers/createReducer';
import { oneDaySeconds } from 'utils/time';

import createDayHourlyData from './helpers/createDayHourlyData';
import createFiveDayHourlyData from './helpers/createFiveDayHourlyData';

/**
 * @typedef {import('../../../types/surf').SurfData} GraphSurfState
 */

/**
 * @type {GraphSurfState}
 */
export const initialState = {
  error: null,
  loading: true,
  units: null,
  utcOffset: null,
  location: null,
  forecastLocation: null,
  offshoreLocation: null,
  overallMaxSurfHeight: null,
  days: null,
  runInitializationTimestamp: null,
  observations: [],
};

const handlers = {};

handlers[FETCH_GRAPH_SURF_FORECAST] = () => initialState;

handlers[FETCH_GRAPH_SURF_FORECAST_SUCCESS] = (
  state,
  { response, desired16DayInterval, isGraphUpdates },
) => {
  const mapFunc = ({ timestamp, utcOffset, surf }) => ({
    timestamp,
    utcOffset,
    surf,
  });

  const data = response.data.surf;

  const returnedDays = createDayHourlyData(data, 16, desired16DayInterval, mapFunc);

  const [lastDayOfData] = returnedDays.slice(-1);
  const [lastHourOfData] = lastDayOfData.slice(-1);

  const missingDays = Array.from({ length: 16 - returnedDays.length }, (_, index) =>
    Array.from({ length: isGraphUpdates ? 24 / desired16DayInterval : 24 }, (_, hourIndex) => ({
      timestamp: lastHourOfData.timestamp + oneDaySeconds * (index + 1) + hourIndex,
      utcOffset: lastHourOfData.utcOffset,
      surf: {
        min: null,
        max: 0,
        optimalScore: null,
        plus: false,
        humanRelation: null,
        raw: {
          min: null,
          max: null,
        },
      },
    })),
  );
  const combinedDays = [].concat(returnedDays, missingDays);

  return {
    ...state,
    loading: false,
    units: response.associated.units,
    utcOffset: response.associated.utcOffset,
    location: response.associated.location,
    forecastLocation: response.associated.forecastLocation,
    offshoreLocation: response.associated.offshoreLocation,
    runInitializationTimestamp: response.associated.runInitializationTimestamp,
    overallMaxSurfHeight: Math.max(...response.data.surf.map(({ surf }) => surf.raw.max)),
    days: combinedDays,
    hourly: createFiveDayHourlyData(response.data.surf, mapFunc),
  };
};

handlers[FETCH_GRAPH_SURF_FORECAST_FAILURE] = (state, { error }) => ({
  ...state,
  error,
  loading: false,
});

handlers[FETCH_GRAPH_LIVE_SURF_FORECAST] = (state) => ({
  // no need for a loading state for observations
  ...state,
});

handlers[FETCH_GRAPH_LIVE_SURF_FORECAST_SUCCESS] = (state, { response, isPremium }) => {
  const hourlyData = createDayHourlyData(response.data, 1, 1);
  const [observations] = hourlyData;

  // Filter observations for non-premium users to show fewer data points
  const filteredObservations = isPremium
    ? observations
    : observations.filter((_, index) => index % 3 === 0);

  return {
    ...state,
    // only add observation data
    observations: filteredObservations,
  };
};

handlers[FETCH_GRAPH_LIVE_SURF_FORECAST_FAILURE] = (state) => ({
  // no need for an error state for observations
  ...state,
});

export default createReducer(handlers, initialState);
