import type { UserState } from 'types/user';

export const PREMIUM_ENTITLEMENT = 'sl_premium';

export const PREMIUM_WITH_ADS_ENTITLEMENT = 'sl_premium_ads';

export const PREMIUM_PLUS_ENTITLEMENT = 'sl_premium_plus';

export const getUserGeotarget = (user: UserState | null = null) => {
  let geotarget = user?.countryCode || 'US';
  const localizedSetting = user?.settings?.feed?.localized;
  if (localizedSetting) {
    geotarget = localizedSetting !== 'LOCALIZED' ? localizedSetting : geotarget;
  }
  return geotarget;
};

export const getUserCountryCode = (user: UserState | null = null) => user?.countryCode || 'US';

export const isPremiumUser = (entitlements: Array<string> | undefined) =>
  entitlements?.includes(PREMIUM_ENTITLEMENT) ?? false;

export const getIsPremiumWithAdsUser = (entitlements: Array<string> | undefined) =>
  entitlements?.includes(PREMIUM_WITH_ADS_ENTITLEMENT) ?? false;

export const isPremiumPlusUser = (entitlements: Array<string> | undefined) =>
  entitlements?.includes(PREMIUM_PLUS_ENTITLEMENT) ?? false;
