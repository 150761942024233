import React from 'react';
import Head from 'next/head';

import config from 'config';
import type { Meta as YoastMeta } from 'types/yoast';

interface Props {
  title: string;
  description?: string;
  urlPath: string;
  image?: string;
  disableImage?: boolean;
  parentUrl?: string;
  parentTitle?: string;
  permalink?: string;
  type?: string;
  yoastMeta?: YoastMeta;
  metaRedirect?: MetaRedirect;
}

interface Link {
  rel: string;
  href: string;
  title?: string;
}

interface MetaRedirect {
  url: string;
  timeout?: number;
}

const MetaTags: React.FC<Props> = ({
  title,
  description,
  urlPath,
  disableImage = false,
  image = config.facebookOGImage,
  parentUrl,
  parentTitle,
  children,
  permalink,
  type = '',
  yoastMeta,
  metaRedirect,
}) => {
  const seoUrl = `${config.hostForSEO}${urlPath}`;
  const url = permalink || `${config.surflineHost}${urlPath}`;
  const link: Link[] = [
    {
      rel: 'canonical',
      href: seoUrl,
    },
  ];
  if (parentUrl && parentTitle) {
    link.push({
      rel: 'start',
      href: `${config.hostForSEO}${parentUrl}`,
      title: parentTitle,
    });
  }

  const meta = [
    {
      name: 'description',
      content: yoastMeta?.['og:description'] || description,
    },
    {
      property: 'og:type',
      content: type,
    },
    {
      property: 'og:site_name',
      content: yoastMeta?.['og:site_name'] || 'Surfline',
    },
    {
      property: 'og:title',
      content: yoastMeta?.['og:title'] || title,
    },
    {
      property: 'og:url',
      content: yoastMeta?.['og:url'] || url,
    },
    {
      property: 'og:description',
      content: yoastMeta?.['og:description'] || description,
    },
    {
      property: 'og:updated_time',
      content: yoastMeta?.['og:updated_time'] || '',
    },
    {
      property: 'canonical',
      content: yoastMeta?.canonical || url,
    },
    {
      property: 'article:section',
      content: yoastMeta?.['article:section'] || '',
    },
    {
      property: 'article:published_time',
      content: yoastMeta?.['article:published_time'] || '',
    },
    {
      property: 'article:updated_time',
      content: yoastMeta?.['article:updated_time'] || '',
    },
    {
      property: 'twitter:card',
      content: yoastMeta?.['twitter:card'] || '',
    },
    {
      property: 'twitter:title',
      content: yoastMeta?.['twitter:title'] || title,
    },
    {
      property: 'twitter:url',
      content: yoastMeta?.['twitter:url'] || url,
    },
    {
      property: 'twitter:description',
      content: yoastMeta?.['twitter:description'] || description,
    },
  ];

  const robotsMeta = config.robots
    ? {
        name: 'robots',
        content: config.robots,
      }
    : null;

  if (robotsMeta) {
    meta.push(robotsMeta);
  }

  const ogImageMeta = !disableImage
    ? {
        property: 'og:image',
        content: image,
      }
    : null;

  if (ogImageMeta) {
    meta.push(ogImageMeta);
  }

  const twitterImageMeta = !disableImage
    ? {
        property: 'twitter:image',
        content: image,
      }
    : null;

  if (twitterImageMeta) {
    meta.push(twitterImageMeta);
  }

  return (
    <Head>
      <title>{title}</title>
      {meta.map((m) => (
        <meta key={`meta-${m.property || m.name}`} {...m} />
      ))}
      {metaRedirect && (
        <meta
          key="meta-redirect"
          httpEquiv="refresh"
          content={`${metaRedirect.timeout || 0}; url=${metaRedirect.url}`}
        />
      )}
      {link.map((l) => (
        <link rel={l.rel} href={l.href} key={`link-${l.rel}`} title={l.title} />
      ))}
      {children}
    </Head>
  );
};

export default MetaTags;
